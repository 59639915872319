// FreeApp.js

import React, { useState, useEffect } from 'react';

const FreeApp = () => {
  const [appData, setAppData] = useState([]);
  const [visibleApps, setVisibleApps] = useState(20);


useEffect(() => {

		updateDocumentTitle();
  
  fetch('/json/app.json')
    .then(response => response.json())
    .then(data => {
const processedData = data.map(app => {
  const dateWithoutWeekday = app.pub_date.replace(/^[a-zA-Z]+,\s/, ''); 
  const parsedDate = new Date(dateWithoutWeekday);

  return {
    ...app,
    pub_date: isNaN(parsedDate) ? null : parsedDate.toLocaleString('en-US', { timeZone: 'UTC' }),
  };
});

      const validData = processedData.filter(app => app.pub_date !== null);
      validData.sort((a, b) => new Date(b.pub_date) - new Date(a.pub_date));

      setAppData(validData);
    })
    .catch(error => console.error('Error fetching data:', error));
}, []);


  const loadMore = () => {
    setVisibleApps(prevVisible => prevVisible + 20);
  };
    const updateDocumentTitle = () => {
    document.title = "AppStore限免APP - GetApp";
  };
  return (
    <div className="free-app-container">
      <div className="free-app-list">
        {appData.slice(0, visibleApps).map((app, index) => (
          <div key={index} className="free-app-item" onClick={() => window.open(app.appstore_link, '_blank')} role="button">
            <img src={app.image} alt={`${app.title} App`} loading="lazy" />
            <p>{app.title}</p>
            <p>{new Date(app.pub_date).toLocaleString()}</p>
          </div>
        ))}
      </div>
      {visibleApps < appData.length && (
        <button className="load-more-button" onClick={loadMore}>
          Load More
        </button>
      )}
    </div>
  );
};

export default FreeApp;
